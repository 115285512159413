import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPRiy-wKkkuxSHgueT7DxYdmnA7ZI5sfw",
  authDomain: "the-rainbow-dev---crm.firebaseapp.com",
  projectId: "the-rainbow-dev---crm",
  storageBucket: "the-rainbow-dev---crm.appspot.com",
  messagingSenderId: "438957026315",
  appId: "1:438957026315:web:8dcab0c24669393604ab0e",
  measurementId: "G-44WGK5DBKY",
};

// Init Firebase

firebase.initializeApp(firebaseConfig);

// Init Services

const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// Get Timestamp from Server

// const timestamp = firebase.firestore.fieldValue.serverTimeStamp;

// Exports

// export { projectFirestore, projectAuth, timestamp };
export { projectFirestore, projectAuth };
