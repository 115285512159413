import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";
import Home from "../views/Home.vue";
import Login from "@/views/auth/Login.vue";
import PageNotFound from "@/views/PageNotFound.vue";

// Auth Guard - For protected routes that need Authentication
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("Current user in Auth Guard: ", user);
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

// NO Auth Guard - To STOP authenticated users from visiting the Login and Register routes
const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("Current user in Auth Guard: ", user);
  if (user) {
    next({ name: "Home" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: requireNoAuth,
  },
  // Catch all to display 404
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
